import React from 'react';
import SectionTitle from './SectionTitle';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const DriverDetailsSection = ({ order, handleInputChange }) => {
  return (
    <>
      <SectionTitle title="Driver Details" />
      <Grid
        container
        justify="space-between"
        spacing={1}
        style={{ maxWidth: '800px' }}
      >
        {/**First row */}
        <Grid
          size={{ lg: 4 }}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <FormControl size="small" fullWidth>
            <InputLabel>Title</InputLabel>
            <Select
              fullWidth
              label="Title"
              name="title"
              value={order.driverDetails.title}
              onChange={handleInputChange}
            >
              <MenuItem value="">NONE </MenuItem>
              <MenuItem value="MR">MR</MenuItem>
              <MenuItem value="MRS">MRS</MenuItem>
              <MenuItem value="MS">MS</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <TextField
            size="small"
            label="First Name"
            value={order.driverDetails.firstName}
            name="firstName"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <TextField
            size="small"
            label="Last Name"
            value={order.driverDetails.lastName}
            name="lastName"
            onChange={handleInputChange}
          />
        </Grid>

        {/**Second row */}

        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <TextField
            size="small"
            type="number"
            label="Age"
            value={order.age}
            name="age"
            onChange={handleInputChange}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <TextField
            size="small"
            label="Email"
            value={order.driverDetails.email}
            name="email"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <TextField
            size="small"
            label="Phone"
            value={order.driverDetails.phone}
            name="phone"
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item lg={4} sm={6}></Grid>
      </Grid>
    </>
  );
};

export default DriverDetailsSection;
