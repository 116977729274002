import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
const Policies = ({ order, setOrder }) => {
  const handleChange = (e) => {
    const values = e.target.value.split('\n');
    setOrder({ ...order, policies: values });
  };
  return (
    <>
      <Typography style={{ textAlign: 'center', marginBottom: 8 }} variant="h6">
        Policies
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        onChange={handleChange}
      />
    </>
  );
};

export default Policies;
